<template>
    <v-container>
        <v-row>
            <v-col cols="12" v-if="hasRole('ANSATT')" class="d-flex">
                <v-sheet
                    v-for="(tjeneste, navn) in antallKoblinger"
                    v-bind:key="navn"
                    v-on:click="$router.push('/hjemme/koblinger/' + tjeneste.id)"
                    color="grey lighten-2"
                    :rounded="true"
                    elevation="0"
                    height="160"
                    width="160"
                    class="mr-8 d-flex flex-wrap flex-column justify-center align-center"
                    style="cursor: pointer"
                >
                    <div class="text-h3">{{ tjeneste.antall }}</div>
                    <br />
                    <div class="text-subtitle-1 text-center">{{ tjeneste.navn }}</div>
                </v-sheet>
            </v-col>
            <v-col cols="6" v-if="mineHjemmetjenester.length > 0">
                <v-card flat>
                    <v-card-title>
                        <v-icon>mdi-calendar-clock</v-icon>
                        <span>Frivillig hos</span>
                    </v-card-title>
                    <v-card-text class="text--primary">
                        <div
                            v-for="(hjemmetjeneste, index) in mineHjemmetjenester"
                            :key="index"
                            v-bind:class="{ 'v-card__date': true, 'v-card--link': true }"
                            v-on:click="editPerson(index)"
                        >
                            <div class="grey lighten-2">
                                <v-icon>mdi-account-multiple</v-icon>
                            </div>
                            <div>
                                <span v-html="hjemmetjeneste.bruker.fulltNavn" style="font-weight: bold"></span>
                                <span v-if="hjemmetjeneste.bruker.mobil">{{ hjemmetjeneste.bruker.mobil }}</span>
                                <span v-if="hjemmetjeneste.bruker.epost">{{ hjemmetjeneste.bruker.epost }}</span>
                                <span v-if="hjemmetjeneste.bruker.adresseLinje1">
                                    <template>{{ hjemmetjeneste.bruker.adresseLinje1 }}</template>
                                    <template v-if="hjemmetjeneste.bruker.postnr">, {{ hjemmetjeneste.bruker.postnr }}</template>
                                    <template v-if="hjemmetjeneste.bruker.poststed">, {{ hjemmetjeneste.bruker.poststed }}</template>
                                </span>
                            </div>
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>

            <v-col cols="6" v-if="mineBesok.length > 0">
                <v-card flat>
                    <v-card-title>
                        <v-icon>mdi-calendar-clock</v-icon>
                        <span>Kommende besøk</span>
                    </v-card-title>
                    <v-card-text class="text--primary">
                        <activities-list :activities="mineBesok"></activities-list>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <person-dialog ref="personDialog" v-model="bruker" rolle="BRUKER_HJEMME" :personer="mineBrukere"></person-dialog>
    </v-container>
</template>

<script>
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import PersonDialog from '@/pages/persons/PersonDialog';

export default {
    name: 'VisitPage',
    components: {
        PersonDialog,
    },
    data() {
        return {
            antallKoblinger: {},
            mineBesok: [],
            mineHjemmetjenester: [],
            bruker: {},
            mineBrukere: [],
        };
    },
    computed: {
        ...mapGetters('api', ['user', 'hasRole']),
    },

    /**
     * created
     */
    created: async function () {
        await this.update();
    },

    methods: {
        ...mapActions('api', ['request', 'formatVisit', 'formatActivity']),

        /**
         * update
         */
        update: async function () {
            const today = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
            const antallKoblinger = {};
            const mineHjemmetjenester = [];
            const hjemmetjenesterIds = {};
            const mineBrukere = [];

            // TODO: change API request
            let response = await this.request({
                method: 'get',
                url: '/hjemmetjeneste',
                params: {
                    status: 'aktiv',
                },
            });

            if (response) {
                for (let hjemmetjeneste of response) {
                    if (hjemmetjeneste.frivillig.id == this.user.id) {
                        hjemmetjeneste = await this.formatVisit(hjemmetjeneste);
                        mineHjemmetjenester.push(hjemmetjeneste);
                        hjemmetjenesterIds[hjemmetjeneste.id] = mineHjemmetjenester.length;

                        mineBrukere.push(hjemmetjeneste.bruker);
                    }

                    if (typeof antallKoblinger[hjemmetjeneste.type.navn] == 'undefined') {
                        antallKoblinger[hjemmetjeneste.type.navn] = { id: hjemmetjeneste.type.id, navn: hjemmetjeneste.type.navn, antall: 1 };
                    } else {
                        antallKoblinger[hjemmetjeneste.type.navn]['antall']++;
                    }
                }
            }

            this.antallKoblinger = antallKoblinger;
            this.mineHjemmetjenester = mineHjemmetjenester;
            this.mineBrukere = mineBrukere;
            this.bruker = {};

            // mine besøk
            response = await this.request({
                method: 'get',
                url: '/person/' + this.user.id + '/aktiviteter',
                params: {
                    aktivitetTypeId: 'HJEMMETJENESTE',
                },
            });

            if (response && Array.isArray(response)) {
                const mineBesok = [];
                for (let aktivitet of response) {
                    aktivitet = await this.formatActivity(aktivitet);
                    if (aktivitet.start > today) {
                        if (aktivitet.referanseId && Object.hasOwn(hjemmetjenesterIds, aktivitet.referanseId)) {
                            const hjemmetjenesterIdex = hjemmetjenesterIds[aktivitet.referanseId];
                            const hjemmetjeneste = this.mineHjemmetjenester[hjemmetjenesterIdex];

                            aktivitet.tittel = hjemmetjeneste.bruker.fulltNavn;
                            mineBesok.push(aktivitet);
                        }
                    }
                }

                this.mineBesok = mineBesok;
            }
        },

        /**
         * editPerson
         */
        editPerson: function (index) {
            this.$refs.personDialog.edit(index);
        },
    },
};
</script>
